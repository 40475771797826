import { render, staticRenderFns } from "./ShopProductItem.vue?vue&type=template&id=c194c202&lang=pug"
import script from "./ShopProductItem.vue?vue&type=script&lang=js"
export * from "./ShopProductItem.vue?vue&type=script&lang=js"
import style0 from "./ShopProductItem.vue?vue&type=style&index=0&id=c194c202&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlySaveButton: require('/app/components/elements/user/SaveButton.vue').default,LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default,LoverlyCard: require('/app/components/elements/containers/Card.vue').default})
