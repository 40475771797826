export default {
    methods: {
        handleRouterLinkClick(model, gaEvent = null) {
            this.$trackEvent('CLICK', {
                name: model.name,
                content_type: model.content_type.slug,
                id: model.id,
            })
        },
    },
}
