
import hasModel from '@/mixins/hasModel'
import impression from '@/mixins/impression'
import outboundClickTrack from '@/mixins/outboundClickTrack'
import handleRouterLinkClick from '@/mixins/handleRouterLinkClick'

export default {
    mixins: [hasModel, impression, outboundClickTrack, handleRouterLinkClick],

    props: {
        category: {
            type: Object,
            default: () => {
                return {}
            },
        },
        to: {
            type: null,
            default: null,
        },
        bigger: Boolean,
        hideImage: Boolean,

        forcedHover: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        apiUrl() {
            return process.env.apiUrl
        },

        isPlanning() {
            return this.model.content_type.url.match(/\/planning.+/)
        },

        taxonomy() {
            return this.category?.name
                ? this.category.name
                : this.model?.taxonomy?.length && !this.isPlanning
                ? this.model.taxonomy
                      .filter((el) => !el.is_hidden)
                      .map((el) => el.name)
                      .join(' ')
                : this.model.content_type?.name || ''
        },

        brands() {
            return (this.model?.taxonomy || []).filter((x) => x.content_type.slug === 'vendors')
        },

        brandVendor() {
            return this.brands.length ? this.brands[0] : null
        },

        metas() {
            return this.model?.metas || {}
        },

        link() {
            return this.to ? this.to : `${this.model.url}`
        },

        brand() {
            return this.metas?.brand || undefined
        },

        price() {
            return this.metas?.price || undefined
        },

        shopLink() {
            return this.metas?.affiliate_link || undefined
        },

        ctaText() {
            const customCta = this.metas?.custom_cta || undefined
            if (customCta && customCta !== '') return customCta
            return 'Shop Now'
        },

        priceText() {
            const price = this.price
            if (!price) return ''
            const customPriceText = this.metas?.custom_price || undefined
            if (customPriceText && customPriceText !== '') return `${customPriceText.replace('{price}', price)}`
            return `$${price}`
        },

        gaEvent() {
            return {
                name: 'view_item',
                data: { method: 'Google', event_category: 'engagement', event_label: 'product-card', value: 0 },
            }
        },
    },
}
