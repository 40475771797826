import { render, staticRenderFns } from "./ListBlock.vue?vue&type=template&id=19723a16&scoped=true&lang=pug"
import script from "./ListBlock.vue?vue&type=script&lang=js"
export * from "./ListBlock.vue?vue&type=script&lang=js"
import style0 from "./ListBlock.vue?vue&type=style&index=0&id=19723a16&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19723a16",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyHeading: require('/app/components/elements/typography/Heading.vue').default,LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default,LoverlyButton: require('/app/components/inputs/Button.vue').default,LoverlyInspirationItem: require('/app/components/pages/wedding-inspiration/InspirationItem.vue').default,LoverlyGridMasonry: require('/app/components/elements/containers/GridMasonry.vue').default,LoverlyAppearanceItem: require('/app/components/pages/home/AppearanceItem.vue').default,LoverlyRealWeddingItem: require('/app/components/pages/real-weddings/RealWeddingItem.vue').default,LoverlyCourseItem: require('/app/components/pages/courses/CourseItem.vue').default,LoverlyVendorItem: require('/app/components/pages/vendors/VendorItem.vue').default,LoverlyDownloadItem: require('/app/components/elements/user/DownloadItem.vue').default,LoverlyAuthorItem: require('/app/components/pages/authors/AuthorItem.vue').default,LoverlyArticleItem: require('/app/components/pages/articles/ArticleItem.vue').default,LoverlyShopProductItem: require('/app/components/pages/products/ShopProductItem.vue').default,LoverlyVideoItem: require('/app/components/cards/VideoItem.vue').default,LoverlyImage: require('/app/components/elements/Image.vue').default,LoverlyLoadingSpinner: require('/app/components/elements/LoadingSpinner.vue').default,LoverlyGuideList: require('/app/components/pages/guides/GuideList.vue').default})
